import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { ThemeProvider } from "@material-ui/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import "./css/index.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import App from "./App";
import Themes from "./theme/Themes";
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
	<BrowserRouter basename="/">
		<SnackbarProvider maxSnack={3} preventDuplicate>
			<ThemeProvider theme={Themes}>
				<CssBaseline />
				<App />
			</ThemeProvider>
		</SnackbarProvider>
	</BrowserRouter>,
	document.getElementById("root")
);
reportWebVitals();