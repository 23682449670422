import Axios from "../../helper/AxiosInstance";
import * as API from "../../helper/Api";
import * as Config from "../../helper/Configs";

export const getGallery = async (store, resort_id) => {
	try {
        let params = {
			resort_id: resort_id ? resort_id : null,
		};
		const response = await Axios.get(API.GALLERY, Axios.apiConfig(false, params));
		const { data } = response;
		const galleryContext = { ...store.state.galleryContext };
        
        galleryContext.gallery = [];
        store.setState({ galleryContext });

        let trigger = false;
        data.response.forEach((element, key) => {
            galleryContext.gallery.push(
                {
                    src : Config.MEDIA_URL + 'images/' + element.image, 
                    thumbnail : Config.MEDIA_URL + 'images/' + element.image, 
                    thumbnailWidth : 320, 
                    thumbnailHeight : 212, 
                    caption : element.value, 
                
                }
            );
            if(data.response.length - key == 1){
                trigger = true;
            }
        });
        let  newGallery = galleryContext.gallery;
        galleryContext.gallery = [];
        store.setState({ galleryContext });
        if(trigger){
            galleryContext.gallery = newGallery;
        }

		store.setState({ galleryContext });
	} catch (error) {

	}
};
