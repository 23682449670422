import Axios from "../../helper/AxiosInstance";
import * as API from "../../helper/Api";

export const getCareers = async (store) => {
	try {
		const response = await Axios.get(API.CAREERS, Axios.apiConfig());
		const { data } = response;
		const careerContext = { ...store.state.careerContext };
        
        careerContext.careers = data.response;

		store.setState({ careerContext });
	} catch (error) {

	}
};
