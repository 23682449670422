import Request from "axios";
import * as Config from "./Configs";

Request.interceptors.request.use(
	function(config) {
		// Do something before request is sent
		if (!config.x_bga) {
			document.body.classList.add("show-loader");
		}
		return config;
	},
	function(error) {
		// Do something with request error
		document.body.classList.remove("show-loader");
		return Promise.reject(error);
	}
);

Request.interceptors.response.use(
	function(response) {
		// Do something with response data
		document.body.classList.remove("show-loader");
		return response;
	},
	function(error) {
		if (!error.status) {
			// network error
			// console.log(error);
		}
		// Do something with response error
		document.body.classList.remove("show-loader");
		return Promise.reject(error);
	}
);

Request.apiConfig = (bg = false, params = false) => {
	let headers = {};
	let baseURL = Config.API_BASE_URL;

	return {
		headers: headers,
		baseURL: baseURL,
		x_bga: bg,
		params: params
	};
};

export default Request;
