import Axios from "../../helper/AxiosInstance";
import * as API from "../../helper/Api";

export const getBlogs = async (store) => {
	try {
		const response = await Axios.get(API.BLOG, Axios.apiConfig());
		const { data } = response;
		const blogContext = { ...store.state.blogContext };
        
        blogContext.blogs = data.response;

		store.setState({ blogContext });
	} catch (error) {

	}
};

export const getBlog = async (store, id) => {
	try {
		const response = await Axios.get(API.BLOG + "/" + id , Axios.apiConfig());
		const { data } = response;
		const blogContext = { ...store.state.blogContext };
        
        blogContext.blog = data.response;

		store.setState({ blogContext });
	} catch (error) {

	}
};
