import React, { lazy, Suspense } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';


import useGlobal from "./store";
const Header = lazy(() => import('./components/Header.jsx'));
const Content = lazy(() => import('./components/Content.jsx'));
const Footer = lazy(() => import('./components/Footer.jsx'));

const styles = makeStyles(theme =>({
	root:{
		minHeight: "100vh",
		display: "flex",
		flexDirection: "column",
	}
}))



function App() {
	const classes = styles();
  const renderLoader = () =>  <Skeleton variant="text" height={100} /> ;
	const [globalState, globalActions] = useGlobal();
	const { getCms } = globalActions.cmsContext;
	const { getHome } = globalActions.homeContext;

  React.useEffect(() => {
    getCms();
    getHome();
	}, []);

  return (
    <Suspense fallback={renderLoader()}>
			<div className={classes.root}>
        <Header />
        <Content />
        <Footer />
    </div>
    </Suspense>
  );
}

export default App;
