import Axios from "../../helper/AxiosInstance";
import * as API from "../../helper/Api";

export const getAvailableRooms = async (store, params) => {
	try {
        const response = await Axios.get(API.ENQUIRY, Axios.apiConfig(false, params));
		const { data } = response;
		const bookingContext = { ...store.state.bookingContext };
        bookingContext.rooms = data.response.availability;
        bookingContext.count = data.response.count;
		store.setState({ bookingContext });
	} catch (error) {

	}
};

export const getRoom = async (store, params) => {
	try {
        const response = await Axios.get(API.ROOM , Axios.apiConfig(false, params));
		const { data } = response;

		const bookingContext = { ...store.state.bookingContext };

		bookingContext.room = data.response;

		store.setState({ bookingContext });
		
	} catch (error) {

	}
};


export const roomBooking = async (store, datas) => {
	try {

		const response = await Axios.post(API.ROOMBOOKING, datas, Axios.apiConfig());
		const { data } = response;

		const bookingContext = { ...store.state.bookingContext };

		bookingContext.bookingResponse = data;

		store.setState({ bookingContext });
		
	} catch (error) {

	}
};