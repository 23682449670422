import Axios from "../../helper/AxiosInstance";
import * as API from "../../helper/Api";

export const getCms = async (store) => {
	try {
		const response = await Axios.get(API.CMS, Axios.apiConfig());
		const { data } = response;
		const cmsContext = { ...store.state.cmsContext };
		data.response.forEach((element) => {
			if (element.cms_page_type == "privacy_policy") {
				cmsContext.privacyPolicy.content = element.cms_content;
			}
			if (element.cms_page_type == "about_us") {
				cmsContext.about.content = element.cms_content;
			}
			if (element.cms_page_type == "terms_conditions") {
				cmsContext.termsConditions.content = element.cms_content;
			}
			if (element.cms_page_type == "success_story") {
				cmsContext.successStory.content = element.cms_content;
			}
			if (element.cms_page_type == "refund_policy") {
				cmsContext.refundPolicy.content = element.cms_content;
			}
			if (element.cms_page_type == "contact_us") {
				if (element.cms_page_item == "address1") {
					cmsContext.contactUs.address1 = element.cms_content;
				}
				if (element.cms_page_item == "address2") {
					cmsContext.contactUs.address2 = element.cms_content;
				}
				if (element.cms_page_item == "contact") {
					cmsContext.contactUs.contact = element.cms_content;
				}
				if (element.cms_page_item == "email") {
					cmsContext.contactUs.email = element.cms_content;
				}
				if (element.cms_page_item == "facebook") {
					cmsContext.contactUs.facebook = element.cms_content;
				}
				if (element.cms_page_item == "instagram") {
					cmsContext.contactUs.instagram = element.cms_content;
				}
				if (element.cms_page_item == "twitter") {
					cmsContext.contactUs.twitter = element.cms_content;
				}
				if (element.cms_page_item == "gplus") {
					cmsContext.contactUs.gplus = element.cms_content;
				}
				if (element.cms_page_item == "logo") {
					cmsContext.contactUs.logo = element.cms_content;
				}
				if (element.cms_page_item == "footer_logo") {
					cmsContext.contactUs.footerLogo = element.cms_content;
				}
				if (element.cms_page_item == "featured_image") {
					cmsContext.contactUs.featuredImage = element.cms_content;
				}
				if (element.cms_page_item == "title") {
					cmsContext.contactUs.title = element.cms_content;
				}
				if (element.cms_page_item == "description") {
					cmsContext.contactUs.description = element.cms_content;
				}
				if (element.cms_page_item == "website") {
					cmsContext.contactUs.website = element.cms_content;
				}
				if (element.cms_page_item == "fax") {
					cmsContext.contactUs.fax = element.cms_content;
				}
			}
			if (element.cms_page_type == "covid") {
				if (element.cms_page_item == "title") {
					cmsContext.covid.title = element.cms_content;
				}
				if (element.cms_page_item == "description") {
					cmsContext.covid.description = element.cms_content;
				}
				if (element.cms_page_item == "pdf") {
					cmsContext.covid.pdf = element.cms_content;
				}
			}
            
		});
		store.setState({ cmsContext });
	} catch (error) {

	}
};
