import Axios from "../../helper/AxiosInstance";
import * as API from "../../helper/Api";

export const getResort = async (store, slug) => {
	try {
		const response = await Axios.get(API.RESORT + "/" + slug , Axios.apiConfig());
		const { data } = response;
		const resortContext = { ...store.state.resortContext };
        
        resortContext.resort = data.response;

		store.setState({ resortContext });
	} catch (error) {

	}
};
