import React from "react";
import globalHook from "use-global-hook";
import * as actions from "./actions";

const initialState = {
	
	cmsContext: {
		privacyPolicy: {
			content: null,
		},
		about: {
			content: null,
		},
		termsConditions: {
			content: null,
		},
		refundPolicy: {
			content: null,
		},
		successStory: {
			content: null,
		},
		contactUs: {
			address1: null,
			address2: null,
			contact: null,
			email: null,
			facebook: null,
			instagram: null,
			twitter: null,
			gplus: null,
			logo: null,
			footerLogo: null,
			featuredImage: null,
			title: null,
			description: null,
			website: null,
			fax: null,
		},
		covid: {
			title: null,
			description: null,
			pdf: null,
		},
		cmsResponse: null,
	},
    homeContext: {
        banners: [],
        resorts: [],
        sliders: [],
    },
    blogContext: {
        blogs: [],
		blog: null,
    },
    careerContext: {
       careers: [],
    },
    galleryContext: {
		gallery: [],
    },
    resortContext: {
		resort: null,
    },
    menuContext: {
		menu: null,
		gallery: [],
    },
    bookingContext: {
		rooms: [],
		count: 0,
		room: null,
		bookingResponse: null
    }
};

const useGlobal = globalHook(React, initialState, actions);

export default useGlobal;
