import Axios from "../../helper/AxiosInstance";
import * as Config from "../../helper/Configs";

export const getMenu = async (store, resort_slug, menu_slug) => {
	try {
		const response = await Axios.get( resort_slug + "/" + menu_slug , Axios.apiConfig());
		const { data } = response;

		const menuContext = { ...store.state.menuContext };
        menuContext.menu = data.response;
		store.setState({ menuContext });

		if(menu_slug == 'photo-gallery'){
			const menuContext = { ...store.state.menuContext };
			menuContext.gallery = [];
			store.setState({ menuContext });
			let trigger = false;
			if(data.response && data.response.menucontents && data.response.menucontents.length){
				data.response.menucontents.forEach((element, key) => {
					menuContext.gallery.push(
						{
							src : Config.MEDIA_URL + 'images/' + element.image, 
							thumbnail : Config.MEDIA_URL + 'images/' + element.image, 
							thumbnailWidth : 320, 
							thumbnailHeight : 212, 
							caption : element.value, 
						
						}
					);
					if(data.response.menucontents.length - key == 1){
						trigger = true;
					}
				});
			}
			let  newGallery = menuContext.gallery;
			menuContext.gallery = [];
			store.setState({ menuContext });
			if(trigger){
				menuContext.gallery = newGallery;
			}
	
			store.setState({ menuContext });
		}
		
	} catch (error) {

	}
};
