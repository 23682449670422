import Axios from "../../helper/AxiosInstance";
import * as API from "../../helper/Api";

export const getHome = async (store) => {
	try {
		const response = await Axios.get(API.HOME, Axios.apiConfig());
		const { data } = response;
		const homeContext = { ...store.state.homeContext };
        
        homeContext.banners = data.response.banners;
        homeContext.resorts = data.response.resorts;
        homeContext.sliders = data.response.sliders;

		store.setState({ homeContext });
	} catch (error) {

	}
};
