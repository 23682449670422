import { createMuiTheme } from "@material-ui/core/styles";

const Theme = createMuiTheme({
	palette: {
		primary: {
			main: "#b57d44",
			dark: "#b57d44"
		},
		background: {
			default: "#d2d3d5"
		}
	},
	typography: {
		useNextVariants: true
	},
	overrides: {
		MuiInputLabel: {
			root: {
				fontFamily: "Ubuntu",
				color: "#fff",
				fontWeight: "600"
			}
		},
		MuiInputBase: {
			input: {
				fontFamily: "Ubuntu",
				color: "#fff",
				fontWeight: "600"
			}
		},
		MuiOutlinedInput: {
			notchedOutline: {
				border: "1px solid #fff !important",
				borderRadius: 0
			}
		},
		MuiMenu: {
			list: {
				minWidth: 120,
			},
		},
		MuiMenuItem: {
			root: {
				fontWeight: 600,
				minHeight: 44,
				'&:hover': {
					backgroundColor: 'rgba(0, 0, 0, 0.30) !important',
				},
				"&$selected": {
					backgroundColor: 'rgba(0, 0, 0, 0.30) !important',
					"&:hover": {
						backgroundColor: 'rgba(0, 0, 0, 0.40) !important',
					},
				},
				'&:focus': {
					backgroundColor: 'rgba(0, 0, 0, 0.30) !important',
				},
			},
		},
		MuiSelect: {
			iconOutlined: {
				top: "calc(50% - 14px)",
			},
		},
	}
});
export default Theme;
